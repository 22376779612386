import React from "react";
import './Content.css'
import {LanguageProps} from "../../utils/interfaces";

export const Content : React.FC<LanguageProps> = ({language}) => {
    return (
        <div className={"content"} style={language === "IND" ? {marginTop : "25%"} : {}}>
            <div className={"item"}>
                <img src={"/traders-icon.png"}/>
                <h3 className={"item-title"}>{language === "ENG" ? "Traders" : "Pemasok"}</h3>
                <p className={"item-text"}>{language === "ENG" ?
                    "You can either buy or sell crops from other users of the platform. Act as an intermediary between farmers, factories."
                    : "Pengguna yang mengumpulkan hasil pertanian dari petani dan menjadi perantara antara para pembeli dan penjual hasil pertanian."}</p>
            </div>
            <div className={"item"}>
                <img src={"/farmer-icon.png"}/>
                <h3 className={"item-title"}>{language === "ENG" ? "Farmers" : "Petani"}</h3>
                <p className={"item-text"}>{language === "ENG" ?
                    "Sell your crops, register your farm, find powerfull connections and get support before starting to plant."
                    : "Para Petani dapat mendaftarkan lahan pertanian, menjual hasil pertanian, terhubung dengan para pembeli & meminta bantuan kebutuhan pertanian."}</p>
            </div>
            <div className={"item"}>
                <img src={"/factory-icon.png"}/>
                <h3 className={"item-title"}>{language === "ENG" ? "Midstream factories" : "Pabrik"}</h3>
                <p className={"item-text"}>{language === "ENG" ?
                    "To people who own or manage a meadstream processing factory, such as a corn drying factory, that buys corn from farmers and traders, and sells it to other enterprises."
                    : "Pengguna yang memiliki atau mengelola pabrik pengeringan atau pembersihan hasil pertanian yang membeli hasil pertanian dari para petani dan pemasok, dan menjual hasil produksi nya kepada konsumen akhir. "}</p>
            </div>
            <div className={"item"}>
                <img src={"/barn-icon.png"}/>
                <h3 className={"item-title"}>{language === "ENG" ? "Downstream factories and livestock farms" : "Konsumen Akhir"}</h3>
                <p className={"item-text"} id={"faq"}>{language === "ENG" ?
                    "Such as commercial feed factories, livestock farm facilities that buy dried crops from midstream processing factories."
                    : "Pengguna yang memiliki atau mengelola pabrik pengolahan hasil pertanian seperti pabrik pakan komersial, peternakan, dll."}</p>
            </div>
    </div>)
}