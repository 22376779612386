
export const engTexts = [
    "Explore a dedicated marketplace where authorized farmers, traders, and factories unite to simplify the corn trade.",
    "Seamlessly connect, trade, and maximize your corn business potential through our intuitive platform.",
    "Convenience of streamlining your supply chain management in one place.",
    "Explore a dedicated agricultural marketplace where authorized farmers, traders, and factories connect and transact."
]

export const indTexts = [
    "Selamat datang di Kultivo, platform jual beli khusus untuk para petani, pedagang, dan pembeli hasil pertanian di Indonesia.",
    "Kami hadir untuk memudahkan komunikasi, transaksi, dan memaksimalkan perdagangan hasil pertanian melalui platform kami.",
    "Kemudahan dalam mengatur manajemen rantai pasokan usaha anda dalam satu tempat.",
    "Mari tingkatkan hasil usaha pertanian anda dengan aplikasi Kultivo!"
]