import React, {useState} from "react";
import './List.css'
import {LanguageProps} from "../../utils/interfaces";

export const List : React.FC<LanguageProps> = ({language}) => {
    const [isFirstItem, setIsFirstItem] = useState(false);
    const [isSecondItem, setIsSecondItem] = useState(false);
    const [isThirdItem, setIsThirdItem] = useState(false);
    const [isFourthItem, setIsFourthItem] = useState(false);
    return (
        <div className={"list"}>
            <div className={"element"}>
                <div className={"element-title"} onClick={() => setIsFirstItem(!isFirstItem)}>
                    <h1 className={"element-head"}>01</h1>
                    <p  className={isFirstItem ? "element-text active" : "element-text"}>{language === "ENG" ? "What do I need to register?" : "Apa saja yang perlu saya siapkan untuk mendaftar?"}</p>
                    <img src={ isFirstItem ? "/cross-icon.png" : "/plus-icon.png"}/>
                </div>
                {
                    isFirstItem ?
                        <p className={"text"}>{ language === "ENG" ?
                            "You will need your NIK, upload your KTP and enter your residential address to register as a user. And to register your business, you will need to upload your NIB or your business details, and your business address."
                            : "Anda akan membutuhkan NIK, foto KTP,  dan memasukkan alamat tinggal Anda untuk mendaftar sebagai Pengguna. Dan untuk mendaftarkan usaha Anda, Anda perlu mengunggah NIB atau detil usaha Anda dan alamat tempat usaha Anda."
                        }</p>
                        : null
                }
            </div>
            <div className={"element"}>
                <div className={"element-title"} onClick={() => setIsSecondItem(!isSecondItem)}>
                    <h1 className={"element-head"}>02</h1>
                    <p className={isSecondItem ? "element-text active" : "element-text"}>{language === "ENG" ?
                        "What do I need to do to start trading?"
                        : "Apa yang saya butuhkan untuk memulai bertransaksi?"}
                    </p>
                    <img src={isSecondItem ? "/cross-icon.png" : "/plus-icon.png"}/>
                </div>
                {
                    isSecondItem ?
                        <p className={"text"}>{ language === "ENG" ?
                            "You need to register on the platform, then pass the two stages of verification: profile and business. Then you will need to post a listing, or connect with other users through their listing. When you open a listing, there is a “Chat” button at the bottom. You will be able to negotiate with other users on their listing and Lock Trade when you agree to transact."
                            : "Anda perlu mendaftar di platform Kultivo, lalu selesaikan 2 tahapan verifikasi: Profil Pengguna dan Verifikasi Usaha. Lalu anda dapat membuat Iklan Jual/Beli atau berinteraksi dengan iklan milik pengguna lainnya untuk memulai bertransaksi. Saat anda menekan iklan milik pengguna lain, anda dapat menekan tombok Chat untuk mulai bernegosiasi dengan pemilik iklan tersebut. Jika anda sudah yakin, anda dapat menekan Kunci Transaksi."
                        }</p>
                        : null
                }
            </div>
            <div className={"element"}>
                <div className={"element-title"} onClick={() => setIsThirdItem(!isThirdItem)}>
                    <h1 className={"element-head"}>03</h1>
                    <p  className={isThirdItem ? "element-text active" : "element-text"}>{language === "ENG" ? "Can I pay in the app?" : "Apakah saya bisa membayar melalui aplikasi?"}</p>
                    <img src={isThirdItem ? "/cross-icon.png" : "/plus-icon.png"} />
                </div>
                {
                    isThirdItem ?
                        <p className={"text"}>{language === "ENG" ?
                            "Not for now. Payments for transactions will be made by Bank Transfer or Cash-on-Delivery (COD)."
                            : "Tidak untuk sekarang. Abda dapat melakukan pembayaran lewat Transfer Bank atau Cash-on-Delivery (COD)."}</p>
                        : null
                }
            </div>
            <div className={"element"} onClick={() => setIsFourthItem(!isFourthItem)}>
                <div className={"element-title"}>
                    <h1 className={"element-head"}>04</h1>
                    <p  className={isFourthItem ? "element-text active" : "element-text"}>{language === "ENG" ? "Can I buy and sell crops safely in the app?" : "Apakah transaksi yang dilakukan di aplikasi ini aman?"}</p>
                    <img src={isFourthItem ? "/cross-icon.png" : "/plus-icon.png"}/>
                </div>
                {isFourthItem ?
                    <p className={"text"}>{language === "ENG" ?
                        "Kultivo will generate contracts for your transaction in the app to make sure your transaction is carried out safely."
                        : "Kultivo akan mengeluarkan kontrak untuk setiap transaksi agar transaksi dapat berjalan dengan lancar dan aman."}
                        </p>
                    : null
                }
            </div>
        </div>
    )
}