import React, {useState} from "react";
import './Landing.css'
import {Footer} from "../../components/Footer/Footer";
import {Header} from "../../components/Header/Header";
import {List} from "../../components/List/List";
import {Content} from "../../components/Content/Content";
import {Main} from "../../components/Main/Main";
import {SetLanguageProps} from "../../utils/interfaces";


export const Landing : React.FC<SetLanguageProps> = ({language, setLanguage}) => {
    return (
        <div className={"landing-wrapper"}>
            <Header language={language} setLanguage={setLanguage}/>
            <body>
                <Main language={language}/>
                <Content language={language}/>
                <List language={language}/>
                <div className={"download-info-container"} id={"app"}>
                    <a href={"https://play.google.com/store/apps/details?id=com.elizarov.agroapp"} target="_blank">
                        <img className={"download-info-img"} src={language === "ENG" ? "/download-info.png" : "/download-info-ind.png"} alt={"download info"}/>
                    </a>
                </div>
            </body>
            <Footer language={language}/>
        </div>
    )
}