import React from "react";
import '../Documentation.css'
import {Header} from "../../../components/Header/Header";
import {Footer} from "../../../components/Footer/Footer";
import {SetLanguageProps} from "../../../utils/interfaces";

export const PrivacyIND : React.FC<SetLanguageProps> = ({language, setLanguage}) => {
    return (
        <div className={"documentation-wrapper"}>
            <Header setLanguage={setLanguage} language={language}/>
            <div className={"documentation-body"}>
                <h1 className={"documentation-title"}>Kebijakan Privasi</h1>
                <div className={"documentation-info"}>
                    <p>Kebijakan Privasi ini merupakan komitmen nyata dari PT Terra World Ventures (selanjutnya disebut sebagai “Kultivo”) untuk menghargai dan melindungi setiap data atau informasi pribadi Pengguna Aplikasi Kultivo (selanjutnya disebut sebagai "Aplikasi").<br/>
                        Kebijakan Privasi ini (beserta syarat-syarat penggunaan dari situs Kultivo sebagaimana tercantum dalam Syarat & Ketentuan dan informasi lain yang tercantum di Aplikasi) menetapkan dasar atas perolehan, pengumpulan, pengolahan, penganalisisan, penampilan, pengiriman, pembukaan, penyimpanan, perubahan, penghapusan dan/atau segala bentuk pengelolaan yang terkait dengan data atau informasi yang mengidentifikasikan atau dapat digunakan untuk mengidentifikasi Pengguna yang Pengguna berikan kepada Kultivo atau yang Kultivo kumpulkan dari Pengguna maupun pihak ketiga (selanjutnya disebut sebagai "Data Pribadi").</p>
                    <p>Dengan mengakses Aplikasi dan/atau mengklik “Daftar” (Register) atau pernyataan serupa yang tersedia di laman pendaftaran Aplikasi, Pengguna menyatakan bahwa setiap Data Pribadi Pengguna merupakan data yang benar dan sah, Pengguna mengakui bahwa ia telah diberitahukan dan memahami ketentuan Kebijakan Privasi ini serta Pengguna memberikan persetujuan kepada Kultivo untuk memperoleh, mengumpulkan, mengolah, menganalisis, menampilkan, mengirimkan, membuka, menyimpan, mengubah, menghapus, mengelola dan/atau mempergunakan data tersebut untuk tujuan sebagaimana tercantum dalam Kebijakan Privasi. Seluruh ketentuan berhuruf kapital yang tidak didefinisikan di Kebijakan Privasi ini akan merujuk pada definisi di Syarat dan Ketentuan. Kebijakan Privasi ini ditulis dalam Bahasa Indonesia.</p>
                    <p>
                        A. Perolehan dan Pengumpulan Data Pribadi Pengguna<br/>
                        B. Penggunaan Data Pribadi<br/>
                        C. Pengungkapan Data Pribadi Pengguna<br/>
                        D. Cookies<br/>
                        E. Pilihan Pengguna dan Transparansi<br/>
                        F. Keamanan, Penyimpanan dan Penghapusan Data Pribadi Pengguna<br/>
                        G. Akses dan Perbaikan Data Pribadi Pengguna atau Penarikan Kembali Persetujuan<br/>
                        H. Pengaduan terkait Perlindungan Data Pribadi Pengguna<br/>
                        I. Hubungi Kami<br/>
                        J. Penyimpanan dan Penghapusan Informasi<br/>
                        K. Pembaruan Kebijakan Privasi<br/>
                    </p>
                </div>
                <div className={"documentation-items"}>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>A. Perolehan dan Pengumpulan Data Pribadi Pengguna</h3>
                        <div className={"documentation-item-text"}>
                            <p>Kultivo mengumpulkan Data Pribadi Pengguna dengan tujuan untuk memproses transaksi Pengguna, mengelola dan memperlancar proses penggunaan Aplikasi sebagaimana dijelaskan dalam Kebijakan Privasi ini, serta tujuan-tujuan lainnya selama diizinkan oleh peraturan perundang-undangan yang berlaku. Adapun data Pengguna yang dikumpulkan adalah sebagai berikut:</p>
                            <p>1. Data yang diserahkan secara mandiri oleh Pengguna, termasuk namun tidak terbatas pada data yang diserahkan pada saat Pengguna:
                                <ul>
                                    <li>membuat atau memperbarui akun Kultivo, termasuk namun tidak terbatas pada nama pengguna (username), alamat email, nomor telepon, nama perusahaan, foto diri Pengguna, foto Kartu Tanda Penduduk atau kartu tanda identitas lainnya, password dan informasi lainnya yang dapat mengidentifikasi Pengguna;</li>
                                    <li>data yang ditambahkan saat mendaftar menjadi Pengguna Aplikasi dan menggunakan Aplikasi;</li>
                                    <li>menghubungi Kultivo, termasuk melalui Layanan Bantuan (customer service); dan/atau</li>
                                    <li>menggunakan fitur pada Aplikasi yang membutuhkan izin akses ke data yang relevan yang tersimpan dalam perangkat Pengguna.</li>
                                </ul>
                            </p>
                            <p>2. Data yang terekam pada saat Pengguna mempergunakan Aplikasi, termasuk namun tidak terbatas pada:
                                <ul>
                                    <li>data lokasi riil atau perkiraannya seperti alamat IP, lokasi Wi-Fi dan geo-location;</li>
                                    <li>data berupa waktu dari setiap aktivitas Pengguna sehubungan dengan penggunaan Aplikasi, termasuk waktu pendaftaran, login dan transaksi;</li>
                                    <li>data penggunaan atau preferensi Pengguna, termasuk interaksi Pengguna dalam menggunakan Aplikasi, pilihan yang disimpan, serta pengaturan yang dipilih. Data tersebut diperoleh menggunakan cookies, pixel tags, dan teknologi serupa yang menciptakan dan mempertahankan pengenal unik;</li>
                                    <li>data perangkat, diantaranya jenis perangkat yang digunakan untuk mengakses Aplikasi, termasuk model perangkat keras, sistem operasi dan versinya; dan/atau</li>
                                    <li>data catatan (log), diantaranya catatan pada server yang menerima data seperti alamat IP perangkat, tanggal dan waktu akses, fitur aplikasi atau laman yang dilihat, proses kerja aplikasi dan aktivitas sistem lainnya, jenis peramban (browser), dan/atau situs atau layanan pihak ketiga yang Pengguna gunakan sebelum berinteraksi dengan Aplikasi.</li>
                                </ul>
                            </p>
                            <p>3. Data yang diperoleh dari sumber lain, termasuk namun tidak terbatas pada:
                                <ul>
                                    <li>data berupa geo-location (GPS) dari mitra usaha Kultivo yang turut membantu Kultivo dalam mengembangkan dan menyajikan layanan-layanan dalam Aplikasi kepada Pengguna;</li>
                                    <li>data berupa email, nomor telepon, dan nama perusahaan dari mitra usaha Kultivo tempat Pengguna membuat atau mengakses akun Kultivo, seperti layanan media sosial, atau situs/aplikasi yang menggunakan application programming interface (API) Kultivo atau yang digunakan Kultivo;</li>
                                    <li>data berupa email dari penyedia layanan pemasaran.</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>B. Penggunaan Data Pribadi Pengguna</h3>
                        <div className={"documentation-item-text"}>
                            <p>Kultivo dapat menggunakan Data Pribadi yang diperoleh dan dikumpulkan dari Pengguna sebagaimana disebutkan dalam bagian sebelumnya untuk hal-hal sebagai berikut:</p>
                            <p>1. Memproses segala bentuk permintaan dan aktivitas yang dilakukan oleh Pengguna melalui Aplikasi, termasuk untuk keperluan pengiriman produk kepada Pengguna.</p>
                            <p>2. Penyediaan fitur-fitur untuk memberikan, mewujudkan, memelihara dan memperbaiki produk dan layanan kami, termasuk:
                                <ul>
                                    <li>menawarkan, memperoleh, menyediakan, atau memfasilitasi layanan melalui Aplikasi; dan/atau</li>
                                    <li>melakukan kegiatan internal yang diperlukan untuk menyediakan layanan pada situs/aplikasi Kultivo, seperti pemecahan masalah software, bug, permasalahan operasional, melakukan analisis data, pengujian, dan penelitian, dan untuk memantau dan menganalisis kecenderungan penggunaan dan aktivitas.</li>
                                </ul>
                            </p>
                            <p>3. Kultivo dapat menggunakan Data Pribadi yang diperoleh dari Pengguna untuk tujuan pemasaran penawaran produk Kultivo.</p>
                            <p>4. Membantu Pengguna pada saat berkomunikasi dengan Penjual dan/atau Layanan Pelanggan Kultivo, diantaranya untuk:
                                <ul>
                                    <li>memeriksa dan mengatasi permasalahan Pengguna;</li>
                                    <li>mengarahkan pertanyaan Pengguna kepada petugas layanan pelanggan yang tepat untuk mengatasi permasalahan;</li>
                                    <li>mengawasi dan memperbaiki tanggapan layanan pelanggan Kultivo;</li>
                                    <li>menghubungi Pengguna melalui email, surat, telepon, fax, dan metode komunikasi lainnya, termasuk namun tidak terbatas, untuk membantu dan/atau proses penyelesaian kendala, serta menyampaikan berita atau notifikasi lainnya sehubungan dengan perlindungan Data Pribadi Pengguna oleh Kultivo, termasuk kegagalan perlindungan Data Pribadi Pengguna;</li>
                                    <li>menggunakan informasi yang diperoleh dari Pengguna untuk tujuan penelitian, pengembangan dan pengujian produk guna meningkatkan keamanan layanan-layanan pada Aplikasi, serta mengembangkan fitur dan produk baru; dan</li>
                                    <li>menginformasikan kepada Pengguna terkait produk, layanan, promosi, studi, survei, berita, perkembangan terbaru, acara dan informasi lainnya, baik melalui Aplikasi maupun melalui media lainnya.</li>
                                </ul>
                            </p>
                            <p>5. Melakukan monitoring ataupun investigasi terhadap aktivitas-aktivitas mencurigakan atau aktivitas yang terindikasi mengandung unsur kecurangan atau pelanggaran terhadap Syarat dan Ketentuan atau ketentuan hukum yang berlaku, serta melakukan tindakan-tindakan yang diperlukan sebagai tindak lanjut dari hasil monitoring atau investigasi aktivitas tersebut.</p>
                            <p>6. Dalam keadaan tertentu, Kultivo mungkin perlu untuk menggunakan ataupun mengungkapkan Data Pribadi Pengguna untuk tujuan penegakan hukum atau untuk pemenuhan persyaratan dan kewajiban peraturan perundang-undangan yang berlaku, termasuk dalam hal terjadinya sengketa atau proses hukum antara Pengguna dan Kultivo, atau dugaan tindak pidana seperti penipuan atau pencurian data.</p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>C. Pengungkapan dan Pengalihan Data Pribadi Pengguna</h3>
                        <div className={"documentation-item-text"}>
                            <p>1. Kultivo berkomitmen untuk menjaga kerahasiaan Data Pribadi Pengguna yang berada di bawah kendali Kultivo dan menjamin tidak ada pengungkapan, penjualan, pengalihan, distribusi dan/atau peminjaman Data Pribadi Pengguna kepada pihak ketiga lain, tanpa persetujuan dari Pengguna, kecuali dalam hal-hal sebagai berikut:
                                <ul>
                                    <li>dibutuhkan adanya pengungkapan Data Pribadi Pengguna kepada mitra atau pihak ketiga lain yang membantu Kultivo dalam menyajikan layanan yang tersedia atau layanan yang di kemudian hari akan tersedia pada Aplikasi dan memproses segala bentuk aktivitas Pengguna dalam Aplikasi.</li>
                                    <li>Kultivo dapat menyediakan Data Pribadi Pengguna kepada mitra usaha sesuai dengan persetujuan Pengguna untuk menggunakan layanan mitra usaha, termasuk diantaranya aplikasi atau situs lain yang telah saling mengintegrasikan API atau layanannya, atau mitra usaha yang mana Kultivo telah bekerjasama untuk menyelenggarakan promosi atau layanan khusus yang tersedia pada Aplikasi.</li>
                                    <li>Kultivo dapat menyediakan Data Pribadi Pengguna kepada vendor, konsultan, mitra pemasaran, firma/lembaga riset, atau penyedia layanan sejenis dalam rangka kegiatan pemasaran yang dilakukan oleh pihak ketiga, peningkatan dan pemeliharaan kualitas layanan Kultivo, serta kegiatan publikasi lainnya.</li>
                                    <li>Pengguna menghubungi Kultivo melalui media publik seperti blog, media sosial, dan fitur tertentu pada Aplikasi, yang mana komunikasi antara Pengguna dan Kultivo tersebut dapat dilihat dan diketahui oleh khalayak ramai.</li>
                                    <li>Kultivo dapat membagikan Data Pribadi Pengguna kepada anak perusahaan dan afiliasinya untuk membantu memberikan layanan atau melakukan pengolahan data untuk dan atas nama Kultivo.</li>
                                    <li>Kultivo mengungkapkan Data Pribadi Pengguna dalam upaya mematuhi kewajiban hukum dan/atau adanya permintaan yang sah dari aparat penegak hukum atau instansi penyelenggara negara yang berwenang.</li>
                                </ul>
                            </p>
                            <p>2. Sehubungan dengan pengungkapan Data Pribadi Pengguna sebagaimana dijelaskan di atas, Kultivo akan/mungkin perlu mengungkapkan Data Pribadi Pengguna kepada penyedia layanan pihak ketiga, agen dan/atau afiliasi atau perusahaan terkait Kultivo, dan/atau pihak ketiga lainnya yang berlokasi di luar wilayah Indonesia. Meskipun demikian, penyedia layanan pihak ketiga, agen dan/atau afiliasi atau perusahaan terkait dan/atau pihak ketiga lainnya tersebut hanya akan mengelola dan/atau memanfaatkan Data Pribadi Pengguna sehubungan dengan satu atau lebih tujuan sebagaimana diatur dalam Kebijakan Privasi ini dan sesuai dengan ketentuan peraturan perundangan-undangan yang berlaku.</p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>D. Cookies (if applicable)</h3>
                        <div className={"documentation-item-text"}>
                            <p>1. Cookies adalah file kecil yang secara otomatis akan mengambil tempat di dalam perangkat Pengguna yang menjalankan fungsi dalam menyimpan preferensi maupun konfigurasi Pengguna selama mengunjungi suatu situs.</p>
                            <p>2. Cookies tersebut tidak diperuntukkan untuk digunakan pada saat melakukan akses data lain yang Pengguna miliki di perangkat komputer Pengguna, selain dari yang telah Pengguna setujui untuk disampaikan.</p>
                            <p>3. Walaupun secara otomatis perangkat komputer Pengguna akan menerima cookies, Pengguna dapat menentukan pilihan untuk melakukan modifikasi melalui pengaturan browser Pengguna yaitu dengan memilih untuk menolak cookies (pilihan ini dapat menghambat tersedianya layanan Kultivo secara optimal pada saat Pengguna mengakses Aplikasi).</p>
                            <p>4. Kultivo dapat menggunakan fitur-fitur yang disediakan oleh pihak ketiga dalam rangka meningkatkan layanan dan konten Kultivo, termasuk diantaranya ialah penilaian, penyesuaian, dan penyajian iklan kepada setiap Pengguna berdasarkan minat atau riwayat kunjungan. Jika Pengguna tidak ingin iklan ditampilkan berdasarkan penyesuaian tersebut, maka Pengguna dapat mengaturnya melalui browser.</p>

                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>E. User Choice and Transparency</h3>
                        <div className={"documentation-item-text"}>
                            <p>1.Pengguna dapat mengakses dan mengubah informasi berupa alamat email, nomor telepon  melalui fitur pengaturan (settings) pada Aplikasi.</p>
                            <p>2.Sepanjang tidak bertentangan dengan ketentuan peraturan perundang-undangan yang berlaku, Pengguna dapat menghubungi Kultivo untuk melakukan penarikan persetujuan terhadap perolehan, pengumpulan, penyimpanan, pengelolaan dan penggunaan data Pengguna. Apabila terjadi demikian maka Pengguna memahami konsekuensi bahwa Pengguna tidak dapat menggunakan layanan Aplikasi maupun layanan Kultivo lainnya.</p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>F. Keamanan, Penyimpanan, dan Penghapusan Data Pribadi Pengguna</h3>
                        <div className={"documentation-item-text"}>
                            <p>1. Kultivo melindungi setiap Data Pribadi Pengguna yang disimpan dalam sistemnya, serta melindungi data tersebut dari akses, penggunaan, modifikasi, pengambilan dan/atau pengungkapan tidak sah dengan menggunakan sejumlah tindakan dan prosedur keamanan termasuk kata sandi Pengguna.</p>
                            <p>2. Walaupun Kultivo telah menggunakan upaya terbaiknya untuk mengamankan dan melindungi Data Pribadi Pengguna, perlu diketahui bahwa pengiriman data melalui Internet tidak pernah sepenuhnya aman. Dengan demikian, Kultivo tidak dapat menjamin 100% keamanan data yang disediakan atau dikirimkan kepada Kultivo oleh Pengguna dan pemberian informasi oleh Pengguna merupakan risiko yang ditanggung oleh Pengguna sendiri.</p>
                            <p>3. Kultivo akan menghapus dan/atau menganonimkan Data Pribadi Pengguna yang ada di bawah kendali Kultivo apabila (i) Data Pribadi Pengguna tidak lagi diperlukan untuk memenuhi tujuan dari pengumpulannya; dan (ii) penyimpanan tidak lagi diperlukan untuk tujuan kepatuhan terhadap peraturan perundang-undangan yang berlaku.</p>
                            <p>4. Mohon diperhatikan bahwa masih ada kemungkinan bahwa beberapa Data Pribadi Pengguna disimpan oleh pihak lain, termasuk instansi penyelenggara negara yang berwenang. Dalam hal kami membagikan Data Pribadi Pengguna kepada instansi penyelenggara negara yang berwenang dan/atau instansi lainnya yang dapat ditunjuk oleh pemerintah yang berwenang atau memiliki kerja sama dengan Kultivo, Pengguna menyetujui dan mengakui bahwa penyimpanan Data Pribadi Pengguna oleh instansi tersebut akan mengikuti kebijakan penyimpanan data masing-masing instansi tersebut.</p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>G. Akses dan Perbaikan Data Pribadi Pengguna atau Penarikan Kembali Persetujuan</h3>
                        <div className={"documentation-item-text"}>
                            <p>1. Kultivo mengambil langkah-langkah yang wajar untuk memastikan bahwa Data Pribadi Pengguna diproses secara akurat dan lengkap. Namun demikian, penting bagi Pengguna untuk memberitahu Kultivo secara tepat waktu tentang segala perubahan pada Data Pribadi Pengguna atau jika ada kesalahan dalam Data Pribadi Pengguna yang berada di bawah kendali Kultivo.</p>
                            <p>2. Pengguna berhak untuk mengakses atau mengoreksi Data Pribadi Pengguna yang berada di bawah kendali Kultivo melalui layanan pelanggan Kultivo yang tersedia dalam Kebijakan Privasi ini. Namun demikian, permohonan tersebut hanya akan diproses oleh Kultivo apabila Pengguna telah menyerahkan bukti identitas yang memadai untuk melakukan akses atau koreksi terhadap data tersebut. Kultivo berhak menolak permohonan untuk mengakses, atau untuk memperbaiki, sebagian atau semua Data Pribadi Pengguna yang Kultivo miliki atau kuasai jika diizinkan atau diperlukan berdasarkan perundang-undangan yang berlaku. Hal ini termasuk dalam keadaan di mana Data Pribadi tersebut dapat berisi referensi kepada orang lain atau di mana permintaan untuk akses atau permintaan untuk mengoreksi adalah untuk alasan yang Kultivo anggap tidak relevan, tidak serius, atau menyulitkan.</p>
                            <p>3. Kultivo dapat membebankan biaya administrasi kepada Pengguna untuk menangani permintaan Pengguna untuk mengakses atau mengoreksi Data Pribadi Pengguna.</p>
                            <p>4. Pengguna dapat menarik persetujuan yang telah Pengguna berikan terkait dengan pemrosesan Data Pribadi Pengguna yang ada di bawah kendali Kultivo dengan mengirimkan permintaan tersebut melalui layanan pelanggan Kultivo yang tercantum dalam Kebijakan Privasi ini. Kultivo akan memproses permintaan Pengguna dalam kurun waktu yang wajar sejak permintaan penarikan persetujuan tersebut disampaikan, dan selanjutnya tidak memproses Data Pribadi Pengguna sesuai dengan permintaan awal Pengguna, kecuali diwajibkan oleh peraturan perundang-undangan yang berlaku. Dalam hal terdapat penarikan persetujuan, Kultivo mungkin tidak dapat melaksanakan kewajiban-kewajibannya berdasarkan setiap perjanjian antara Pengguna dengan Kultivo. Sehubungan dengan hal tersebut, terdapat juga kemungkinan bahwa dikarenakan Kultivo tidak dapat melaksanakan kewajiban-kewajibannya oleh karena Pengguna menarik persetujuannya, setiap hubungan hukum antara Pengguna dan Kultivo menjadi berakhir dan tidak dapat dilanjutkan.</p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>H. Pengaduan terkait Perlindungan Data Pribadi Pengguna</h3>
                        <div className={"documentation-item-text"}>
                            <p>1. Jika Pengguna memiliki kekhawatiran tentang penanganan atau perlakuan Kultivo terhadap Data Pribadi Pengguna atau jika Pengguna yakin bahwa privasinya telah dilanggar, Pengguna dapat menghubungi Kultivo melalui layanan pelanggan Kultivo atau kepada kontak Kultivo yang tercantum dalam Kebijakan Privasi ini dengan menjelaskan identitas dan sifat keluhan Pengguna.</p>
                            <p>2. Kultivo akan menyelidiki keluhan Pengguna dan berupaya untuk memberikan tanggapan terhadap keluhan tersebut dalam kurun waktu yang wajar setelah menerima keluhan yang disampaikan oleh Pengguna.</p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>I. Hubungi Kami</h3>
                        <div className={"documentation-item-text"}>
                            <p>Dalam hal Pengguna memiliki pertanyaan, komentar, keluhan, atau klaim mengenai Kebijakan Privasi ini atau Pengguna ingin mendapatkan akses dan/atau melakukan koreksi terhadap Data Pribadi miliknya, silakan hubungi layanan pelanggan Kultivo melalui email: cs.kultivo@gmail.com</p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>J.Penyimpanan dan Penghapusan Informasi</h3>
                        <div className={"documentation-item-text"}>
                            <p>Kultivo akan menyimpan informasi selama akun Pengguna tetap aktif sesuai dengan ketentuan peraturan hukum yang berlaku dan Pengguna dapat melakukan penghapusan informasi dengan mengajukan permohonan penghapusan informasi melalui email layanan pelanggan.</p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>K. Perubahan Kebijakan Privasi</h3>
                        <div className={"documentation-item-text"}>
                            <p>Kultivo dapat sewaktu-waktu melakukan perubahan atau pembaruan terhadap Kebijakan Privasi ini. Kultivo menyarankan agar Pengguna membaca secara seksama dan memeriksa halaman Kebijakan Privasi ini dari waktu ke waktu untuk mengetahui perubahan apapun. Dengan tetap mengakses dan menggunakan layanan Aplikasi maupun layanan Kultivo lainnya, maka Pengguna dianggap menyetujui perubahan-perubahan dalam Kebijakan Privasi.</p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer language={language}/>
        </div>
    )
}