import React from "react";
import './Header.css'
import {SetLanguageProps} from "../../utils/interfaces";

export const Header : React.FC<SetLanguageProps> = ({language, setLanguage}) => {
    return (
        <header className={"header"}>
            <img src={"/kultivo-logo.png"} alt={"kultivo logo"}/>
            <div className={"header-menu"}>
                <div className={"menu"}>
                    <a className={"menu-item"} href={"#about"}>{language === "ENG" ? "About" : "Tentang Kami"}</a>
                    <a className={"menu-item"} href={"#faq"}>FAQ</a>
                    <a className={"menu-item"} href={"#app"}>App</a>
                </div>
                <div className={"switch"}>
                    <p className={"switch_lang"}>
                        <input className={language === "ENG" ? "switch_button active" : "switch_button"} type="button" onClick={() => setLanguage("ENG")} value={"ENG"}/>
                        <input className={language === "IND" ? "switch_button active" : "switch_button"} type="button" onClick={() => setLanguage("IND")} value={"IND"}/>
                    </p>
                </div>
            </div>
        </header>
    )
}