import '../Documentation.css'
import React from "react";
import {Header} from "../../../components/Header/Header";
import {Footer} from "../../../components/Footer/Footer";
import {SetLanguageProps} from "../../../utils/interfaces";

export const TermsIND : React.FC<SetLanguageProps> = ({language, setLanguage}) => {
    return (
        <div className={"documentation-wrapper"}>
            <Header setLanguage={setLanguage} language={language}/>
            <div className={"documentation-body"}>
                <h1 className={"documentation-title"}>Syarat & Ketentuan</h1>
                <div className={"documentation-info"}>
                    <p>Selamat datang di aplikasi Kultivo!</p>
                    <p>Syarat & ketentuan yang ditetapkan di bawah ini mengatur pemakaian jasa yang ditawarkan oleh PT Terra World Ventures terkait penggunaan situs www.kultivo.com beserta seluruh aplikasi turunannya yang berbasis Android. Pengguna disarankan membaca dengan seksama karena dapat berdampak kepada hak dan kewajiban Pengguna di bawah hukum.</p>
                    <p>Dengan mendaftar dan/atau menggunakan aplikasi Kultivo, maka Pengguna dianggap telah membaca, mengerti, memahami dan menyetujui semua isi dalam Syarat & ketentuan. Syarat & Ketentuan ini merupakan bentuk kesepakatan yang dituangkan dalam sebuah perjanjian yang sah antara Pengguna dengan PT Terra World Ventures. Jika Pengguna tidak menyetujui salah satu, pesebagian, atau seluruh isi Syarat & Ketentuan, maka Pengguna tidak diperkenankan menggunakan layanan pada aplikasi Kultivo.</p>
                    <p>A. Definisi<br/>
                        B. Transaksi<br/>
                        С. Layanan Bantuan<br/>
                        D. Penolakan Jaminan dan Batasan Tanggung Jawab<br/>
                        E. Pelepasan<br/>
                        F. Ganti Rugi<br/>
                        G. Pilihan Hukum<br/>
                        H. Pembaharuan<br/>
                    </p>
                </div>
                <div className={"documentation-items"}>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>A. Definisi</h3>
                        <div className={"documentation-item-text"}>
                            <p>1. PT Terra World Ventures adalah suatu perseroan terbatas yang menjalankan kegiatan usaha jasa pengelolaan web portal dengan laman www.kultivo.com beserta seluruh aplikasi turunannya yang berbasis Android. Selanjutnya disebut Kultivo.</p>
                            <p>2. Aplikasi adalah aplikasi Kultivo, yaitu aplikasi jual beli atas Produk yang dijual oleh Penjual dan dibeli oleh Pembeli dengan mekanisme Transaksi sebagaimana diatur pada Syarat dan Ketentuan ini.</p>
                            <p>3. Layanan adalah layanan berupa fitur-fitur pada Aplikasi yang disediakan oleh Kultivo kepada Pengguna.</p>
                            <p>4. Syarat & ketentuan adalah perjanjian antara Pengguna dan Kultivo yang berisikan seperangkat peraturan yang mengatur hak, kewajiban, tanggung jawab Pengguna dan Kultivo, serta tata cara penggunaan sistem layanan Kultivo.</p>
                            <p>5. Pengguna adalah pihak yang menggunakan layanan Kultivo, termasuk namun tidak terbatas pada Pembeli, Penjual, maupun pihak lain yang sekedar berkunjung ke Aplikasi Kultivo. Jenis-jenis Pengguna serta peran pada Aplikasi adalah sebagai berikut:
                                <ul>
                                    <li>Petani adalah Pengguna yang hanya dapat menjual Produk pada Aplikasi dan termasuk Penjual yang dapat menerima Bantuan dari Pembeli.</li>
                                    <li>Pemasok adalah Pengguna yang dapat menjual maupun membeli Produk pada Aplikasi, yang berarti individu atau badan hukum yang bertindak sebagai perantara bagi Petani dan Pembeli.</li>
                                    <li>Pabrik adalah Pengguna yang dapat menjual maupun membeli Produk pada Aplikasi, yang berarti individu atau badan hukum yang memiliki atau mengelola pabrik pengolahan, seperti pabrik pengeringan jagung yang membeli jagung dari Petani dan Pemasok, dan menjualnya kepada Pembeli Akhir.</li>
                                    <li>Pembeli Akhir adalah Pengguna yang hanya dapat membeli Produk pada Aplikasi.</li>
                                </ul>
                            </p>
                            <p>6. Pembeli adalah Pengguna terdaftar yang melakukan pembelian atas Produk yang dijual oleh Penjual pada Aplikasi Kultivo.</p>
                            <p>7. Penjual adalah Pengguna terdaftar yang menjual hasil Produk dan/atau melakukan penawaran atas suatu Produk kepada para Pengguna Aplikasi Kultivo.</p>
                            <p>8. Produk adalah komoditi berupa hasil pertanian yang memenuhi kriteria sebagaimana diatur dalam Perjanjian Jual Beli antara Penjual dan Pembeli.</p>
                            <p>9. Bantuan adalah bantuan Produk untuk penunjang proses produksi Produk yang diberikan oleh Pembeli kepada Penjual, berupa namun tidak terbatas pada benih, pupuk, pestisida, herbisida, dan lain-lain yang rinciannya disepakati pada Perjanjian Jual Beli.</p>
                            <p>10. Perjanjian Jual Beli adalah perjanjian jual beli beserta seluruh lampirannya, yang ditandatangani oleh dan antara Penjual dan Pembeli atas kesepakatan terkait Bantuan dan Produk pada Aplikasi Kultivo.</p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>B. Transaksi</h3>
                        <div className={"documentation-item-text"}>
                            <p>1. Pembeli wajib bertransaksi melalui prosedur transaksi yang telah ditetapkan oleh Kultivo. Penjual dan Pembeli wajib menyepakati keseluruhan spesifikasi Produk dan Bantuan dan menandatangani Perjanjian Jual Beli.</p>
                            <p>2. Saat melakukan pembelian Produk, Pembeli menyetujui bahwa:
                                <ul>
                                    <li>Pembeli bertanggung jawab untuk membaca, memahami, dan menyetujui informasi/deskripsi keseluruhan Produk (termasuk didalamnya namun tidak terbatas pada kualitas, kuantitas, dan lainnya) sebelum membuat tawaran atau komitmen untuk membeli kepada Penjual.</li>
                                    <li>Pembeli mengakui bahwa Kultivo tidak menjamin kesesuaian Produk yang ditawarkan oleh Penjual kepada Pembeli pada Aplikasi.</li>
                                    <li>Pengguna masuk ke dalam Perjanjian yang mengikat secara hukum untuk kegiatan jual-beli Produk ketika Pengguna melakukan transaksi jual-beli pada Aplikasi.</li>
                                    <li>Kultivo tidak mengalihkan kepemilikan secara hukum atas Produk-Produk dari Penjual kepada Pembeli.</li>
                                </ul>
                            </p>
                            <p>3. Pembeli memahami dan menyetujui bahwa ketersediaan kuantiti Produk merupakan tanggung jawab Penjual yang menawarkan Produk tersebut.</p>
                            <p>4. Pembeli dan Penjual memahami dalam hal terdapat ketidaksesuaian Produk dan/atau Bantuan, Pembeli atau Penjual wajib menyepakati modifikasi harga atas Produk melalui Aplikasi dengan memperbaharui Perjanjian.</p>
                            <p>5. Pembeli memahami sepenuhnya dan menyetujui bahwa segala transaksi pembayaran yang dilakukan antara Pembeli dan Penjual  adalah merupakan tanggung jawab pribadi dari Pembeli.</p>
                            <p>6. Pembeli disarankan untuk memeriksa dan melakukan pembayaran sesuai dengan nominal tagihan yang telah disepakati oleh Penjual dan Pembeli yang tertera pada halaman transaksi dan invoice. </p>
                            <p>7. Penjual wajib memberikan foto dan informasi produk dengan lengkap dan jelas, serta memberikan informasi atas perkembangan penanaman Produk kepada Pembeli hingga Produk selesai dipanen oleh Penjual melalui fitur chat, yang kemudian wajib memberikan konfirmasi atas rincian spesifikasi dan jumlah Produk final sesuai hasil panen.</p>
                            <p>8. Apabila terdapat ketidaksesuaian antara spesifikasi dan jumlah Produk yang disepakati Penjual dan Pembeli, maka Penjual dan Pembeli wajib memperbaharui spesifikasi Produk serta harga yang harus dibayarkan oleh Pembeli pada Aplikasi.</p>
                            <p>9. Demi menjaga kenyamanan Pembeli dalam bertransaksi, Penjual memahami dan menyetujui bahwa Kultivo berhak melakukan moderasi toko Penjual apabila Penjual melakukan penolakan, pembatalan dan/atau tidak merespon pesanan Produk milik Pembeli dengan dugaan untuk memanipulasi transaksi, pelanggaran atas Syarat dan Ketentuan, dan/atau kecurangan atau penyalahgunaan lainnya.</p>
                            <p>10. Penjual menyetujui dan memahami bahwa dengan menerima data informasi Pembeli yang terdapat dalam Aplikasi/Aplikasi wajib menjaga kerahasiaan dan dilarang menyalahgunakan data informasi Pembeli dalam bentuk apapun. Kultivo berhak, tanpa pemberitahuan sebelumnya, melakukan investigasi dan memberikan sanksi terhadap dugaan atau laporan penyalahgunaan data Pembeli.</p>
                            <p>11. Harga Produk yang terdapat dalam Aplikasi Kultivo adalah harga yang ditetapkan oleh Penjual dan/atau Pembeli.</p>
                            <p>12. Penjual dan/atau Pembeli dilarang menetapkan harga yang tidak wajar pada Produk yang ditawarkan melalui Aplikasi.</p>
                            <p>13. Penjual dilarang menjual barang lain selain Produk sesuai yang didefinisikan pada Syarat dan Ketentuan Aplikasi Kultivo.</p>
                            <p>14. Pembeli memahami dan menyetujui bahwa kesalahan keterangan harga dan informasi lainnya yang disebabkan tidak terbaharuinya halaman Aplikasi Kultivo dikarenakan browser/ISP yang dipakai Pembeli adalah tanggung jawab Pembeli.</p>
                            <p>15. Penjual memahami dan menyetujui bahwa kesalahan ketik yang menyebabkan keterangan harga atau informasi lain menjadi tidak benar/sesuai adalah tanggung jawab Penjual. Perlu diingat dalam hal ini, apabila terjadi kesalahan pengetikan keterangan harga Produk yang tidak disengaja, Penjual berhak menolak pesanan Produk yang dilakukan oleh pembeli.</p>
                            <p>16. Pengguna memahami dan menyetujui bahwa setiap masalah dan/atau perselisihan yang terjadi akibat ketidaksepahaman antara Penjual dan Pembeli tentang harga bukanlah merupakan tanggung jawab Kultivo.</p>
                            <p>17. Penjual wajib menginformasikan tarif pengiriman Produk kepada Pembeli dan Pembeli wajib menyetujui tarif pengiriman Produk tersebut sebelum Penjual melakukan pengiriman atas Produk</p>
                            <p>18. Pengguna memahami dan menyetujui jika ditemukan adanya indikasi kecurangan, pelanggaran Syarat dan Ketentuan Kultivo atau ketentuan hukum yang berlaku, maka Kultivo berwenang untuk melakukan tindakan-tindakan yang diperlukan termasuk namun tidak terbatas pada tindakan pembatasan pilihan metode pembayaran, melakukan pembatalan transaksi tanpa pemberitahuan sebelumnya.</p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>C. Layanan Bantuan</h3>
                        <div className={"documentation-item-text"}>
                            <p>1. Kultivo menyediakan Layanan Bantuan untuk Pengguna melalui Customer Service pada aplikasi whatsapp, Pengguna dapat menghubungi Layanan Bantuan Kultivo melalui chat whatsapp dengan nomor +6285213888252.</p>
                            <p>2. Kultivo berwenang mengambil keputusan atas permasalahan-permasalahan transaksi yang belum terselesaikan akibat tidak adanya kesepakatan penyelesaian, baik antara Penjual dan Pembeli, dengan melihat bukti-bukti yang ada. Keputusan Kultivo adalah keputusan akhir yang tidak dapat diganggu gugat dan mengikat pihak Penjual dan Pembeli untuk mematuhinya.</p>
                            <p>3. Dalam menggunakan fitur ini, Pembeli dan Penjual diharapkan memberikan bukti-bukti transaksi jual beli berupa foto barang, nota pembelian, slip resi pengiriman dan bukti-bukti penunjang lainnya yang dapat menjadi dasar pembenar atas setiap argumen yang dikeluarkan oleh masing-masing pihak.</p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>D. Penolakan Jaminan dan Batasan Tanggung Jawab</h3>
                        <div className={"documentation-item-text"}>
                            <p>1. Aplikasi Kultivo adalah aplikasi dengan model Customer to Customer Marketplace, yang menyediakan layanan kepada Pengguna untuk dapat menjadi Penjual maupun Pembeli pada Aplikasi Kultivo. Dengan demikian transaksi yang terjadi adalah transaksi antar Pengguna, sehingga Pengguna memahami bahwa batasan tanggung jawab Kultivo secara proporsional adalah sebagai penyedia jasa aplikasi.</p>
                            <p>2. Kultivo selalu berupaya untuk menjaga Layanan pada Aplikasi aman, nyaman, dan berfungsi dengan baik, namun kami tidak dapat menjamin operasi terus-menerus atau akses ke Layanan kami dapat selalu sempurna. Informasi dan data dalam Aplikasi memiliki kemungkinan tidak terjadi secara real time.</p>
                            <p>3. Pengguna setuju bahwa Anda memanfaatkan Layanan Kultivo atas risiko Pengguna sendiri, dan Layanan Kultivo diberikan kepada Anda pada "SEBAGAIMANA ADANYA" dan "SEBAGAIMANA TERSEDIA".</p>
                            <p>4. Sejauh diizinkan oleh hukum yang berlaku, Kultivo (termasuk Induk Perusahaan, direktur, dan karyawan) adalah tidak bertanggung jawab, dan Anda setuju untuk tidak menuntut Kultivo bertanggung jawab, atas segala kerusakan atau kerugian (termasuk namun tidak terbatas pada hilangnya uang, reputasi, keuntungan, atau kerugian tak berwujud lainnya) yang diakibatkan secara langsung atau tidak langsung dari :
                                <ul>
                                    <li>Penggunaan atau ketidakmampuan Pengguna dalam menggunakan Layanan Kultivo.</li>
                                    <li>Harga, Pengiriman atau petunjuk lain yang tersedia dalam layanan Kultivo.</li>
                                    <li>Keterlambatan atau gangguan dalam Layanan Kultivo.</li>
                                    <li>Kelalaian dan kerugian yang ditimbulkan oleh masing-masing Pengguna.</li>
                                    <li>Kualitas Produk.</li>
                                    <li>Pengiriman Produk</li>
                                    <li>Pelanggaran Hak atas Kekayaan Intelektual.</li>
                                    <li>Perselisihan antar Pengguna.</li>
                                    <li>Pencemaran nama baik pihak lain.</li>
                                    <li>Setiap penyalahgunaan Produk  yang sudah dibeli pihak Pembeli.</li>
                                    <li>Virus atau perangkat lunak berbahaya lainnya (bot, script, automation tool selain fitur pada Aplikasi Kultivo, hacking tool) yang diperoleh dengan mengakses, atau menghubungkan ke layanan Kultivo.</li>
                                    <li>Gangguan, bug, kesalahan atau ketidakakuratan apapun dalam Layanan Kultivo.</li>
                                    <li>Kerusakan pada perangkat keras Pengguna dari penggunaan setiap Layanan Kultivo.</li>
                                    <li>Isi, tindakan, atau tidak adanya tindakan dari pihak ketiga, termasuk terkait dengan Produk yang ada dalam Aplikasi Kultivo yang diduga rusak dan/atau tidak layak.</li>
                                    <li>Tindak penegakan yang diambil sehubungan dengan akun Pengguna.</li>
                                    <li>Adanya tindakan peretasan yang dilakukan oleh pihak ketiga kepada akun Pengguna.</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>E. Pelepasan</h3>
                        <div className={"documentation-item-text"}>
                            <p>Jika Anda memiliki perselisihan dengan satu atau lebih Pengguna, Anda melepaskan Kultivo (termasuk Induk Perusahaan, Direktur, dan karyawan) dari klaim dan tuntutan atas kerusakan dan kerugian (aktual dan tersirat) dari setiap jenis dan sifatnya, yang dikenal dan tidak dikenal, yang timbul dari atau dengan cara apapun berhubungan dengan sengketa tersebut, termasuk namun tidak terbatas pada kerugian yang timbul dari pembelian Produk. Dengan demikian maka Pengguna dengan sengaja melepaskan segala perlindungan hukum (yang terdapat dalam undang-undang atau peraturan hukum yang lain) yang akan membatasi cakupan ketentuan pelepasan ini.</p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>F. Ganti Rugi</h3>
                        <div className={"documentation-item-text"}>
                            <p>Pengguna akan melepaskan Kultivo dari tuntutan ganti rugi dan menjaga Kultivo (termasuk Induk Perusahaan, direktur, dan karyawan) dari setiap klaim atau tuntutan, termasuk biaya hukum yang wajar, yang dilakukan oleh pihak ketiga yang timbul dalam hal Anda melanggar Perjanjian ini, penggunaan Layanan Kultivo yang tidak semestinya dan/ atau pelanggaran Anda terhadap hukum atau hak-hak pihak ketiga.</p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>G. Pilihan Hukum</h3>
                        <div className={"documentation-item-text"}>
                            <p>Perjanjian ini akan diatur oleh dan ditafsirkan sesuai dengan hukum Republik Indonesia, tanpa memperhatikan pertentangan aturan hukum. Anda setuju bahwa tindakan hukum apapun atau sengketa yang mungkin timbul dari, berhubungan dengan, atau berada dalam cara apapun berhubungan dengan Aplikasi dan/atau Perjanjian ini akan diselesaikan secara eksklusif dalam yurisdiksi pengadilan Republik Indonesia.</p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>H. Pembaharuan</h3>
                        <div className={"documentation-item-text"}>
                            <p>Syarat & ketentuan mungkin diubah dan/atau diperbaharui dari waktu ke waktu tanpa pemberitahuan sebelumnya. Kultivo menyarankan agar anda membaca secara seksama dan memeriksa halaman Syarat & ketentuan ini dari waktu ke waktu untuk mengetahui perubahan apapun. Dengan tetap mengakses dan menggunakan layanan Kultivo, maka pengguna dianggap menyetujui perubahan-perubahan dalam Syarat & Ketentuan.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer language={language}/>
        </div>
    )
}