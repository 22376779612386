import React, {useState} from 'react';
import './App.css';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {Landing} from "./pages/Landing/Landing";
import {PrivacyENG} from "./pages/Documentation/Privacy/PrivacyENG";
import {TermsENG} from "./pages/Documentation/Terms/TermsENG";
import {TermsIND} from "./pages/Documentation/Terms/TermsIND";
import {PrivacyIND} from "./pages/Documentation/Privacy/PrivacyIND";

function App() {
  const [language, setLanguage] = useState<"ENG" | "IND">("ENG")
  return (
      <Router>
          <div className={"wrapper"}>
          <Routes>
              <Route path={"/"} element={<Navigate to="/landing"/>}/>
              <Route path={"/landing"} element={<Landing language={language} setLanguage={setLanguage}/>}/>
              <Route path={"/privacy"} element={language === "ENG" ? <PrivacyENG language={language} setLanguage={setLanguage}/> : <PrivacyIND setLanguage={setLanguage} language={language}/>}></Route>
              <Route path={"/terms"} element={language === "ENG" ? <TermsENG language={language} setLanguage={setLanguage}/> : <TermsIND language={language} setLanguage={setLanguage}/>}></Route>
          </Routes>
          </div>
      </Router>
  );
}

export default App;
