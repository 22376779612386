import '../Documentation.css'
import React from "react";
import {Header} from "../../../components/Header/Header";
import {Footer} from "../../../components/Footer/Footer";
import {SetLanguageProps} from "../../../utils/interfaces";

export const TermsENG : React.FC<SetLanguageProps> = ({language, setLanguage}) => {
    return (
        <div className={"documentation-wrapper"}>
            <Header setLanguage={setLanguage} language={language}/>
            <div className={"documentation-body"}>
                <h1 className={"documentation-title"}>Terms and Conditions</h1>
                <div className={"documentation-info"}>
                    <p>Welcome to the Kultivo app!</p>
                    <p>The terms & conditions set forth below govern the use of the services offered by PT Terra World Ventures in relation to the use of the www.kultivo.com website and all of its Android-based derivative applications. Users are advised to read it carefully because it may impact the User's rights and obligations under the law.</p>
                    <p>By registering and/or using the Kultivo application, the User is deemed to have read, understood, understood and agreed to all contents in the Terms & Conditions. These Terms & Conditions are a form of agreement set forth in a valid agreement between the User and PT Terra World Ventures. If the User does not agree to one, part, or all of the contents of the Terms & Conditions, then the User is not allowed to use the services in the Kultivo application.</p>
                    <p>A. Definition<br/>
                        B. Transaction<br/>
                        C. Assistance Service<br/>
                        D. Disclaimer of Warranties and Limitation of Liability<br/>
                        E. Waiver<br/>
                        F. Compensation<br/>
                        G. Choice of Law<br/>
                        H. Renewal<br/>
                    </p>
                </div>
                <div className={"documentation-items"}>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>A. Definition</h3>
                        <div className={"documentation-item-text"}>
                            <p>1. PT Terra World Ventures is a limited liability company that carries out business activities for web portal management services with the www.kultivo.com page and all of its Android-based derivative applications. Hereinafter referred to as Kultivo.</p>
                            <p>2. Application is the Kultivo application, which is a buying and selling application for Products sold by Sellers and purchased by Buyers with the Transaction mechanism as stipulated in these Terms and Conditions.</p>
                            <p>3. Services are services in the form of features in the Application provided by Kultivo to Users.</p>
                            <p>4. Terms & conditions are an agreement between the User and Kultivo which contains a set of rules governing the rights, obligations, responsibilities of the User and Kultivo, as well as procedures for using the Kulvo service system.</p>
                            <p>5. Users are parties who use Kultivo services, including but not limited to Buyers, Sellers, or other parties who simply visit the Kultivo Application. The types of users and roles in the application are as follows:
                                <ul>
                                    <li>Farmers are Users who can only sell Products on the Application and include Sellers who can receive Support from Buyers.</li>
                                    <li>Traders are Users who can sell or buy Products on the Application, which means individuals or legal entities that act as intermediaries for Farmers and Buyers.</li>
                                    <li>Factory is a User who can sell or buy Products on the Application, which means an individual or legal entity that owns or manages a processing plant, such as a corn drying factory that buys corn from Farmers and Suppliers, and sells it to Final Buyers.</li>
                                    <li>End Users are Users who can only buy Products in the Application.</li>
                                </ul>
                            </p>
                            <p>6. Buyers are registered Users who make purchases of Products sold by Sellers on the Kultivo Application.</p>
                            <p>7. Sellers are registered Users who sell Products and/or make offers for a Product to Kultivo Application Users.</p>
                            <p>8. Products are commodities in the form of agricultural products that meet the criteria as stipulated in the Sale and Purchase Agreement between the Seller and the Buyer.</p>
                            <p>9. Support is assistance to aid the production process of Product being transacted, provided by the Buyer to the Seller, in the form of but not limited to seeds, fertilizers, pesticides, herbicides, and others, the details of which are agreed upon in the Sale and Purchase Agreement.</p>
                            <p>10. Sale and Purchase Agreement is a contract for sale and purchase of Product along with all of its attachments, which is signed by and between the Seller and the Buyer for the agreement related to Support and Product on the Kultivo Application.</p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>B. Transaction</h3>
                        <div className={"documentation-item-text"}>
                            <p>1. 	Buyers are required to transact through transaction procedures set by Kultivo. Sellers and Buyers are required to agree on all Product specifications and Support and sign a Sale and Purchase Agreement.</p>
                            <p>2. 	When purchasing a Product, the Buyer agrees that:
                                <ul>
                                    <li>The Buyer is responsible for reading, understanding and agreeing to the information/description of the entire product (including but not limited to quality, quantity and others) before making an offer or commitment to buy from the Seller.</li>
                                    <li>The Buyer acknowledges that Kultivo does not guarantee the correctness of the Products offered by the Seller to the Buyer on the Application.</li>
                                    <li>The User enters into a legally binding Agreement for product trading activities when the User makes a sale and purchase transaction on the Application.</li>
                                    <li>Kultivo does not legally transfer ownership of the Products from the Seller to the Buyer.</li>
                                </ul>
                            </p>
                            <p>3. The Buyer understands and agrees that the availability of Product quantity is the responsibility of the Seller who offers the Product.</p>
                            <p>4. Buyers and sellers understand that in the event that there is a Product and/or Support discrepancy, the Buyer or Seller must agree to modify the price of the product through the application by updating the agreement.</p>5. The Buyer fully understands and agrees that all payment transactions made between the Buyer and Seller is the personal responsibility of the Buyer.<p>6. Buyers are advised to check and make payments according to the nominal bill agreed upon by the Seller and Buyer and stated on the transaction page.</p>
                            <p>7. The Seller is obliged to provide complete and clear photos and product information, as well as provide information on the planting progress of Product to the Buyer until the Product has been harvested by the Seller via the chat feature, who is then obliged to provide confirmation of the detailed specifications and quantity of the final Product according to the harvest yield.</p>
                            <p>8. If there is a discrepancy between the specifications and quantity of Products agreed upon by the Seller and Buyer, the Seller and Buyer are obliged to update the Product specifications and the price that must be paid by the Buyer on the Application.</p>
                            <p>9. In order to maintain the Buyer's convenience in transactions, the Seller understands and agrees that Kultivo has the right to moderate the Seller's shop if the Seller refuses, cancels and/or does not respond to the Buyer's Product order with suspicion of manipulating transactions, violating the Terms and Conditions, and/or fraud or other abuse.</p>
                            <p>10. The Seller agrees and understands that by receiving the Buyer's information data contained in the Application/Application, he is obliged to maintain confidentiality and is prohibited from misuse of the Buyer's information data in any form. Kultivo has the right, without prior notification, to conduct investigations and impose sanctions on allegations or reports of misuse of Buyer data.</p>
                            <p>11. Product prices contained in the Kultivo Application are the prices set by the Seller and/or Buyer.</p>
                            <p>12. Sellers and/or Buyers are prohibited from setting unreasonable prices for Products offered through the Application.</p>
                            <p>13. Sellers are prohibited from selling goods other than Products as defined in the Kultivo Application Terms and Conditions.</p>
                            <p>14. The Buyer understands and agrees that errors in price information and other information caused by the Kultivo Application page not being updated due to the browser/ISP used by the Buyer are the Buyer's responsibility.</p>
                            <p>15. The Seller understands and agrees that typographical errors that cause price information or other information to be incorrect/inappropriate are the Seller's responsibility. Please remember in this case, if there is an accidental error in typing the Product price information, the Seller has the right to refuse the Product order made by the Buyer.</p>
                            <p>16. Users understand and agree that any problems and/or disputes that occur due to disagreements between the Seller and Buyer regarding prices are not Kultivo's responsibility.</p>
                            <p>17. The Seller is obliged to inform the Buyer of the Product shipping rates and the Buyer is obliged to agree to the Product shipping rates before the Seller sends the Products.</p>
                            <p>18. Users understand and agree that if indications of fraud, violation of Kultivo's Terms and Conditions or applicable legal provisions are found, Kultivo is authorized to take necessary actions including but not limited to limiting payment method choices, canceling transactions without prior notification.</p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>C. Assistance Service</h3>
                        <div className={"documentation-item-text"}>
                            <p>1. Kultivo provides Assistance Services for Users via Customer Service on the WhatsApp application. Users can contact Kultivo Assistance Services via WhatsApp chat with the number +6285213888252.</p>
                            <p>2. Kultivo has the authority to make decisions on transaction problems that have not been resolved due to the absence of a settlement agreement, both between the Seller and the Buyer, by looking at the existing evidence. Kultivo's decision is a final decision that cannot be contested and is binding on the Seller and Buyer to comply with it.</p>
                            <p>3. When using this feature, Buyers and Sellers are expected to provide evidence of buying and selling transactions in the form of photos of goods, purchase receipts, delivery receipt slips and other supporting evidence which can be used as a justification for any arguments issued by each party.</p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>D. Disclaimer of Warranties and Limitation of Liability</h3>
                        <div className={"documentation-item-text"}>
                            <p>1. The Kultivo Application is an application with a Customer to Customer Marketplace model, which provides services to Users to become Sellers or Buyers on the Kultivo Application. Thus the transactions that occur are transactions between Users, so Users understand that the limitation of Kultivo's responsibility proportionally is as an application service provider.</p>
                            <p>2. Kultivo always strives to keep the Service on the Application safe, comfortable and functioning properly, but we cannot guarantee continuous operation or access to our Service can always be perfect. The information and data in the Application may not occur in real time.</p>
                            <p>3. The User agrees that you utilize the Kultivo Service at the User's own risk, and the Kultivo Service is provided to you on an "AS IS" and "AS AVAILABLE" basis.</p>
                            <p>4. 	To the extent permitted by applicable law, Kultivo (including its Parent Company, directors and employees) is not responsible, and you agree not to hold Kultivo liable, for any damage or loss (including but not limited to loss of money, reputation, gains or other intangible losses) resulting directly or indirectly from:
                                <ul>
                                    <li>User's usage or inability to use Kultivo Services.</li>
                                    <li>Price, Delivery or other instructions available in the Kultivo service.</li>
                                    <li>Delays or interruptions in Kultivo Services.</li>
                                    <li>Negligence and losses incurred by each User.</li>
                                    <li>Product Quality.</li>
                                    <li>Product Delivery.</li>
                                    <li>Intellectual Property Rights Violation.</li>
                                    <li>Disputes between Users.</li>
                                    <li>Defamation of other parties.</li>
                                    <li>Any misuse of the Products that have been purchased by the Buyer.</li>
                                    <li>Viruses or other malicious software (bots, scripts, automation tools other than features in the Kultivo Application, hacking tools) obtained by accessing, or connecting to Kultivo services.</li>
                                    <li>Any glitches, bugs, errors or inaccuracies in the Kultivo Services.</li>
                                    <li>Damage to the User's hardware from use of any Kultivo Services.</li>
                                    <li>Content, actions, or inaction of third parties, including those related to Products in the Kultivo Application that are allegedly damaged and/or inappropriate.</li>
                                    <li>Enforcement actions taken in connection with User accounts.</li>
                                    <li>There is a hacking attempt carried out by a third party on the User's account.</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>E. Waiver</h3>
                        <div className={"documentation-item-text"}>
                            <p>If you have a dispute with one or more users, you waive Kultivo (including the parent company, directors, and employees) from claims and demands for damages and losses (actually and implicitly) of any kind and nature, known and unknown, arising out of or in any way related to such dispute, including but not limited to losses arising from the purchase of the Prohibited Products on Type of Product. Thus, the User deliberately waives any legal protections (existing in any other law or regulation) that would limit the scope of the terms of this waiver.</p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>F. Compensation</h3>
                        <div className={"documentation-item-text"}>
                            <p>Users will release Kultivo from claims for compensation and hold Kultivo (including the Parent Company, directors and employees) harmless from any claims or demands, including reasonable legal fees, made by third parties arising in the event of your violation of this Agreement, use of Kultivo Services inappropriate and/or your violation of the law or the rights of third parties.</p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>G. Choice of Law</h3>
                        <div className={"documentation-item-text"}>
                            <p>This Agreement will be governed by and interpreted in accordance with the laws of the Republic of Indonesia, without regard to conflict of law rules. You agree that any legal action or dispute that may arise from, relate to, or be in any way connected with the Application and/or this Agreement will be resolved exclusively within the jurisdiction of the courts of the Republic of Indonesia.</p>
                        </div>
                    </div>
                    <div className={"documentation-item"}>
                        <h3 className={"documentation-item-title"}>H. Renewal</h3>
                        <div className={"documentation-item-text"}>
                            <p>Terms & conditions may be changed and/or updated from time to time without prior notice. Kultivo recommends that you read carefully and check this Terms & Conditions page from time to time for any changes. By continuing to access and use Kultivo services, users are deemed to have agreed to the changes in the Terms & Conditions.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer language={language}/>
        </div>
    )
}