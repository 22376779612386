import React from "react";
import './Footer.css';
import {LanguageProps} from "../../utils/interfaces";
import {Link} from "react-router-dom";

export const Footer : React.FC<LanguageProps> = ({language}) => {
    return (
       <footer className={"footer"}>
           <div className={"water-marks"}>
               <p>© Kultivo, 2023</p>
               <p><a href={"https://cybertech.ai/#service"} target="_blank">© Designed by Cyber-IT</a></p>
           </div>
           <div className={"privacy"}>
               <Link to={"/privacy"}>
                   <p className={"privacy-text"}>The privacy policy</p>
               </Link>
               <Link to={"/terms"}>
                   <p className={"privacy-text"}>Terms of service</p>
               </Link>
           </div>
       </footer>
    )
}