import '../Documentation.css'
import React from "react";
import {Header} from "../../../components/Header/Header";
import {Footer} from "../../../components/Footer/Footer";
import {SetLanguageProps} from "../../../utils/interfaces";

export const PrivacyENG : React.FC<SetLanguageProps> = ({language, setLanguage}) => {
    return (
        <div className={"documentation-wrapper"}>
            <Header setLanguage={setLanguage} language={language}/>
            <div className={"documentation-body"}>
                <h1 className={"documentation-title"}>The Privacy Policy</h1>
                <div className={"documentation-info"}>
                    <p>This Privacy Policy is a real commitment from PT Terra World Ventures (hereinafter referred to as “Kultivo”) to respect and protect any personal data or information of Kultivo Application Users (hereinafter referred to as “Application”).<br/>
                    This Privacy Policy (along with the terms of use of the Kultivo site as stated in the Terms & Conditions and other information listed in the Application) establishes the basis for obtaining, collecting, processing, analysing, displaying, sending, opening, storing, changing, deleting and/ or any form of management related to data or information that identifies or can be used to identify Users that Users provide to Kultivo or that Kultivo collects from Users or third parties (hereinafter referred to as "Personal Data").</p>

                    <p>By accessing the Application and/or clicking "Register" or a similar statement available on the Application registration page, the User declares that each User's Personal Data is true and valid data, the User acknowledges that he has been notified and understands the provisions of this Privacy Policy and The user gives consent to Kultivo to obtain, collect, process, analyse, display, transmit, open, store, modify, delete, manage and/or use the data for the purposes stated in the Privacy Policy. All capitalized provisions that are not defined in this Privacy Policy will refer to the definitions in the Terms and Conditions. This Privacy Policy is written in Indonesian.</p>

                    <p>A. Acquisition and Collection of User Personal Data<br/>
                    B. Use of Personal Data C. Disclosure of User Personal Data<br/>
                    D. Cookies E. User Choice and Transparency<br/>
                    F. Security, Storage and Deletion of User Personal Data<br/>
                    G. Access and Correction of User Personal Data or Withdrawal of Consent<br/>
                    H. Complaints regarding User Personal Data Protection<br/>
                    I.  Contact us<br/>
                    J. Information Retention and Deletion<br/>
                    K. Privacy Policy Update<br/>
                    </p>
                </div>
                <div className={"documentation-items"}>
                        <div className={"documentation-item"}>
                            <h3 className={"documentation-item-title"}>A. Acquisition and Collection of User Personal Data</h3>
                            <div className={"documentation-item-text"}>
                                <p>Kultivo collects User Personal Data for the purpose of processing User transactions, managing and expediting the process of using the Application as described in this Privacy Policy, as well as other purposes as long as permitted by applicable laws and regulations. The User data collected is as follows:</p>
                                <p>1. Data submitted independently by the User, including but not limited to data submitted when the User:
                                    <ul>
                                        <li>create or update a Kultivo account, including but not limited to username, email address, telephone number, company name, photo of the User, photo of Identity Card or other identity card, password and other information that can identify the User;</li>
                                        <li>data added when registering to become an Application User and using the Application;</li>
                                        <li>contact Kultivo, including through Customer Service; and/or</li>
                                        <li>use features on the Application that require access permission to relevant data stored on the User's device.</li>
                                    </ul>
                                </p>
                                <p>2. Data recorded when Users use the Application, including but not limited to:
                                    <ul>
                                        <li>real or approximate location data such as IP address, Wi-Fi location and geo-location;</li>
                                        <li>data in the form of the time of each User's activity in connection with the use of the Application, including registration, login and transaction times;</li>
                                        <li>usage data or User preferences, including User interactions in using the Application, saved choices, and selected settings. This data is obtained using cookies, pixel tags, and similar technologies that create and maintain unique identifiers;</li>
                                        <li>device data, including the type of device used to access the Application, including the hardware model, operating system and version; and/or</li>
                                        <li>log data, including records on servers that receive data such as device IP addresses, date and time of access, application features or pages viewed, application work processes and other system activities, type of browser (browser), and/or sites or services third parties that Users use before interacting with the Application.</li>
                                    </ul>
                                </p>
                                <p>3. Data obtained from other sources, including but not limited to:
                                    <ul>
                                        <li>data in the form of geo-location (GPS) from Kultivo's business partners who also assist Kultivo in developing and providing services in the Application to Users;</li>
                                        <li>data in the form of e-mail, telephone numbers, and company names from Kultivo business partners where Users create or access Kultivo accounts, such as social media services, or sites/applications that use Kultivo's application programming interface (API) or those used by Kultivo;</li>
                                        <li>data in the form of emails from marketing service providers.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className={"documentation-item"}>
                            <h3 className={"documentation-item-title"}>B. Usage of User Personal Data</h3>
                            <div className={"documentation-item-text"}>
                                <p>Kultivo can use Personal Data obtained and collected from Users as mentioned in the previous section for the following matters:</p>
                                <p>1. Process all forms of requests and activities carried out by Users through the Application, including for the purpose of sending products to Users.</p>
                                <p>2. Providing features to provide, realize, maintain and improve our products and services, including:
                                    <ul>
                                        <li>offer, obtain, provide or facilitate services through the Application; and/or</li>
                                        <li>perform internal activities necessary to provide services on the Kultivo website/application, such as troubleshooting software, bugs, operational issues, conducting data analysis, testing, and research, and to monitor and analyze usage trends and activity.</li>
                                    </ul>
                                </p>
                                <p>3. Kultivo may use Personal Data obtained from Users for marketing purposes for Kultivo product offerings.</p>
                                <p>4. Assist Users when communicating with Sellers and/or Kultivo Customer Service, including to:
                                    <ul>
                                        <li>inspect and resolve User problems;</li>
                                        <li>direct User questions to the right customer service officers to solve problems;</li>
                                        <li>monitor and improve Kultivo customer service responses;</li>
                                        <li>contact Users by email, letter, telephone, fax and other communication methods, including but not limited to assisting and/or troubleshooting processes, as well as delivering news or other notifications regarding the protection of User's Personal Data by Kultivo, including failure to protect Personal Data User;</li>
                                        <li>use information obtained from Users for the purpose of research, development and product testing in order to improve the security of services on the Application, as well as develop new features and products; and</li>
                                        <li>inform Users regarding products, services, promotions, studies, surveys, news, the latest developments, events and other information, both through the Application and through other media.</li>
                                    </ul>
                                </p>
                                <p>5. Monitor or investigate suspicious activities or activities indicated to contain elements of fraud or violations of the Terms and Conditions or applicable legal provisions, as well as take the necessary actions as a follow-up to the results of monitoring or investigation of these activities.</p>
                                <p>6. Under certain circumstances, Kultivo may need to use or disclose User's Personal Data for law enforcement purposes or to fulfill the requirements and obligations of applicable laws and regulations, including in the event of a dispute or legal process between User and Kultivo, or an alleged criminal act such as fraud or data theft.</p>
                            </div>
                        </div>
                        <div className={"documentation-item"}>
                            <h3 className={"documentation-item-title"}>C. Disclosure and Transfer of User Personal Data</h3>
                            <div className={"documentation-item-text"}>
                                <p>1. Kultivo is committed to maintaining the confidentiality of User Personal Data under the control of Kultivo and guarantees that there is no disclosure, sale, transfer, distribution and/or lending of User Personal Data to other third parties, without the consent of the User, except in cases as following:
                                    <ul>
                                        <li>it is necessary to disclose User Personal Data to partners or other third parties who assist Kultivo in presenting available services or services that will later be available on the Application and process all forms of User activity in the Application</li>
                                        <li>Kultivo can provide User Personal Data to business partners in accordance with User agreement to use business partner services, including among other applications or other sites that have integrated APIs or services with each other, or business partners with which Kultivo has collaborated to organize special promotions or services available on Application.</li>
                                        <li>Kultivo may provide User Personal Data to vendors, consultants, marketing partners, research firms/institutions, or similar service providers in the context of marketing activities carried out by third parties, improving and maintaining the quality of Kultivo's services, and other publication activities.</li>
                                        <li>Users contact Kultivo through public media such as blogs, social media, and certain features on the Application, where the communication between the User and Kultivo can be seen and known by the general public.</li>
                                        <li>Kultivo may share User Personal Data with its subsidiaries and affiliates to help provide services or perform data processing for and on behalf of Kultivo.</li>
                                        <li>Kultivo discloses User Personal Data in an effort to comply with legal obligations and/or there is a valid request from law enforcement officials or an authorized state administration agency.</li>
                                    </ul>
                                </p>
                                <p>2. In connection with the disclosure of User Personal Data as described above, Kultivo will/may need to disclose User Personal Data to third party service providers, agents and/or affiliates or companies related to Kultivo, and/or third parties others located outside the territory of Indonesia. However, third party service providers, agents and/or affiliates or related companies and/or other third parties will only manage and/or utilize User Personal Data in connection with one or more purposes as regulated in this Privacy Policy and in accordance with regulatory provisions. applicable laws.</p>
                            </div>
                        </div>
                        <div className={"documentation-item"}>
                            <h3 className={"documentation-item-title"}>D. Cookies (if applicable)</h3>
                            <div className={"documentation-item-text"}>
                                <p>1. Cookies are small files that will automatically take place on the User's device and carry out the function of storing the User's preferences and configuration while visiting a site.</p>
                                <p>2. These cookies are not intended to be used when accessing other data that the User has on the User's computer device, other than what the User has agreed to submit.</p>
                                <p>3. Even though the User's computer device will automatically accept cookies, the User can choose to make modifications through the User's browser settings, namely by choosing to reject cookies (this choice can hinder the optimal availability of Kultivo services when the User accesses the Application).</p>
                                <p>4. Kultivo can use features provided by third parties in order to improve Kultivo's services and content, including assessing, customizing and serving advertisements to each User based on interests or visit history. If the User does not want the ad to be displayed based on these adjustments, then the User can set it through the browser.</p>

                            </div>
                        </div>
                        <div className={"documentation-item"}>
                            <h3 className={"documentation-item-title"}>E. User Choice and Transparency</h3>
                            <div className={"documentation-item-text"}>
                                <p>1. Users can access and change information in the form of e-mail addresses, telephone numbers through the settings feature in the Application.</p>
                                <p>2. As long as it does not conflict with the provisions of applicable laws and regulations, Users can contact Kultivo to withdraw their consent to the acquisition, collection, storage, management and use of User data. If this happens, the User understands the consequences that the User will not be able to use the Application services or other Kultivo services.</p>
                            </div>
                        </div>
                        <div className={"documentation-item"}>
                            <h3 className={"documentation-item-title"}>F. Security, Storage and Deletion of User Personal Data</h3>
                            <div className={"documentation-item-text"}>
                                <p>1. Kultivo protects any User Personal Data stored in its system, and protects such data from unauthorized access, use, modification, retrieval and/or disclosure by using a number of security measures and procedures including User passwords.</p>
                                <p>2. Although Kultivo has used its best efforts to secure and protect User Personal Data, please be aware that data transmission over the Internet is never completely secure. Thus, Kultivo cannot guarantee 100% security of data provided or sent to Kultivo by Users and providing information by Users is at the User's own risk.</p>
                                <p>3. Kultivo will delete and/or anonymize User Personal Data under Kultivo's control if (i) User Personal Data is no longer needed to fulfill the purpose for which it was collected; and (ii) storage is no longer required for the purpose of compliance with the applicable laws and regulations.</p>
                                <p>4.	Please note that there is still a possibility that some User Personal Data is stored by other parties, including authorized state administration agencies. In the event that we share User Personal Data with authorized state administration agencies and/or other agencies that may be appointed by the authorized government or have collaboration with Kultivo, Users agree and acknowledge that the storage of User Personal Data by these agencies will follow their respective data storage policies of each of these agencies.</p>
                            </div>
                        </div>
                        <div className={"documentation-item"}>
                            <h3 className={"documentation-item-title"}>G. Access and Correction of User Personal Data or Withdrawal of Consent</h3>
                            <div className={"documentation-item-text"}>
                                <p>1. Kultivo takes reasonable steps to ensure that User Personal Data is processed accurately and completely. However, it is important for Users to notify Kultivo in a timely manner about any changes to User Personal Data or if there are errors in User Personal Data that is under Kultivo's control.</p>
                                <p>2. Users have the right to access or correct User Personal Data which is under Kultivo's control through Kultivo customer service as provided in this Privacy Policy. However, the request will only be processed by Kultivo if the User has submitted sufficient proof of identity to access or correct the data. Kultivo has the right to refuse requests to access, or to correct, some or all of the User Personal Data that Kultivo owns or controls if permitted or required under applicable law. This includes circumstances where the Personal Data may contain references to other people or where a request for access or a request for correction is h for reasons Kultivo deems irrelevant, not serious, or difficult.</p>
                                <p>3.	Kultivo may charge Users an administration fee for handling User requests to access or correct User Personal Data.</p>
                                <p>4. Users can withdraw the consent that Users have provided regarding the processing of User Personal Data under Kultivo's control by sending such a request through Kultivo customer service as listed in this Privacy Policy. Kultivo will process the User's request within a reasonable time since the request to withdraw consent is submitted, and will not process the User's Personal Data in accordance with the User's initial request, unless required by applicable laws and regulations. In the event of withdrawal of consent, Kultivo may not be able to carry out its obligations under any agreement between the User and Kultivo. In connection with this, there is also the possibility that because Kultivo cannot carry out its obligations because the User withdraws his consent, any legal relationship between the User and Kultivo will end and cannot be continued.</p>
                            </div>
                        </div>
                        <div className={"documentation-item"}>
                            <h3 className={"documentation-item-title"}>H. Complaints regarding User Personal Data Protection</h3>
                            <div className={"documentation-item-text"}>
                                <p>1. If Users have concerns about Kultivo's handling or treatment of User Personal Data or if Users believe that their privacy has been violated, Users can contact Kultivo through Kultivo customer service or to the Kultivo contacts listed in this Privacy Policy by explaining the identity and nature of the User's complaint.</p>
                                <p>2. Kultivo will investigate the User's complaint and endeavor to provide a response to the complaint within a reasonable time after receiving the complaint submitted by the User.</p>
                            </div>
                        </div>
                        <div className={"documentation-item"}>
                            <h3 className={"documentation-item-title"}>I. Contact Us</h3>
                            <div className={"documentation-item-text"}>
                                <p>In the event that Users have questions, comments, complaints or claims regarding this Privacy Policy or Users wish to gain access and/or make corrections to their Personal Data, please contact Kultivo customer service via email: cs.kultivo@gmail.com.</p>
                            </div>
                        </div>
                        <div className={"documentation-item"}>
                            <h3 className={"documentation-item-title"}>J. Information Retention and Deletion</h3>
                            <div className={"documentation-item-text"}>
                                <p>Kultivo will store information as long as the User's account remains active in accordance with applicable legal regulations and Users can delete information by submitting a request for deletion of information via customer service email.</p>
                            </div>
                        </div>
                        <div className={"documentation-item"}>
                            <h3 className={"documentation-item-title"}>K. Privacy Policy Changes</h3>
                            <div className={"documentation-item-text"}>
                                <p>Kultivo may make changes or updates to this Privacy Policy from time to time. Kultivo recommends that Users read carefully and check this Privacy Policy page from time to time for any changes. By continuing to access and use Application services and other Kultivo services, the User is deemed to agree to changes in the Privacy Policy.</p>
                            </div>
                        </div>

                </div>
            </div>
            <Footer language={language}/>
        </div>
    )
}