import React, {useEffect, useState} from "react";
import './Main.css'
import {LanguageProps} from "../../utils/interfaces";
import {engTexts, indTexts} from "../../utils/texts";

export const Main : React.FC<LanguageProps> = ({language}) => {
    const images = ['/indicator_1.png', '/indicator_2.png' , '/indicator_3.png', '/indicator_4.png']
    const [currentImage, setCurrentImage] = useState<string>(images[0])
    const [currentText, setCurrentText] = useState<string>(language === "ENG" ? engTexts[0] : indTexts[0])
    const googlePlayImg = document.getElementById("google-play-img");
    if (googlePlayImg) {
        googlePlayImg.addEventListener("click", function() {
            window.open("https://play.google.com/store/apps/details?id=com.elizarov.agroapp", "_blank");
        });
    }
    useEffect(() => {
        const intervalId = setInterval(() => {
            const currentIndex = images.indexOf(currentImage);
            const nextIndex = (currentIndex + 1) % images.length;
            setCurrentText((language === "ENG" ? engTexts[nextIndex] : indTexts[nextIndex]))
            setCurrentImage(images[nextIndex]);
        }, 3000);
        return () => {
            clearInterval(intervalId);
        };
    }, [currentImage, images]);
    return (<div className={"main"}>
        <div className={"download-back"} onClick={() => {
            const currentIndex = images.indexOf(currentImage);
            const nextIndex = (currentIndex + 1) % images.length;
            setCurrentText((language === "ENG" ? engTexts[nextIndex] : indTexts[nextIndex]))
            setCurrentImage(images[nextIndex]);
        }}>
            <h1 className={"download-title"}>
                {language === "ENG"
                ? "Download  Kultivo app"
                : "Unduh app Kultivo"}
            </h1>
            <p className={"download-block"}>
                <div className={"download-text"}>{currentText}</div>
                <img className={"indicators"} src={currentImage} alt={"indicators"}/>
                <img className={"google-play"} src={"/google-play.png"} alt={"google play"} id="google-play-img"/>
            </p>


        </div>
        <div className={"right-block"}>
            <div className={"qr-back"}>
                <img className={"hands"} src={"/hands.png"} alt={"hands"}/>
                <img className={"qr"} src={"/qr.png"} alt={"qr"}/>
            </div>
            <div className={"main-title"} id={"about"}>{language === "ENG" ? "Who needs this app": "Siapa yang membutuh kan Kultivo?"}</div>
        </div>
    </div>)
}